@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  overflow-x:hidden;
}

.color{
  background-color: rgba(242, 153, 74, 0.1);
}
.price{
  background-image: linear-gradient(142deg, #D58936 100%, #D58936 100%, #D58936 100%);
}

.silver{
  background-image: linear-gradient(142deg, #F2F2F2 100%, #D3490C 48.67%, #F99F1B 115%);
}

.freemium{
  background-image: linear-gradient(142deg, #C22206 100%, #C22206 100%, #C22206 100%);
}

.price, .silver, .freemium {
  transition: all 0.8s ease-in-out;
}

.price:hover, .silver:hover, .freemium:hover {
  /* transform: scale(1.1, 1.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); */
}

.price::after, .silver::after,.freemium::after {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}


.active{
  color:#B30000;
 }